// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Borrar cliente</h2>
                <p>
                    Borrar a un cliente, eliminará todo su registro, por lo que solo debe hacerse cuando sea necesario, pues no se podrán recuperar sus facturas u otros datos.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/clients/remove/<b><span className="error">:masterToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>removed: </b> true / false, confirmación que el cliente fué eliminado.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> Esta es <span className="error">la llave de operación de tu cliente</span>, cada vez que quiera emitir, cancelar u obtener facturas, así como consultar los catálogos del SAT a través de nuestro API.
                        </p>
                    </li>
                </ul>
                <p>
                    Una vez eliminado un cliente, para regresar tendrá que darse de alta de nuevo.
                </p>
                <SectionNavigate next={`/api/referencia/clientes-buscar`} previous={`/api/referencia/clientes-editar`}/>
            </div>
            <div className="side">
                <CodeBlock title="Subir CSD" requests={clienteScripts.borrar.requests} response={clienteScripts.borrar.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
